import { getCachedValue } from '@portals/core-immobilien/src/utils';
import { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { setHeadersAttachToBaseProps } from '../src/utils/myraHeaders';
import { savelyResolvePage } from '../src/utils/savelyResolvePage';
import AnyPage from './[...slugs]';

export default AnyPage;
const CACHING_TIME_CONTENT_PAGES = +(process.env.CACHING_TIME_CONTENT_PAGES || 0);

export const getServerSideProps: GetServerSideProps = async function ({ res, req, preview, previewData }) {
  const keySuffix = preview ? '-preview' : '';
  const cacheKey = `baseprops-startpage${keySuffix}`;
  const baseProps = await getCachedValue(cacheKey, preview ? 0 : CACHING_TIME_CONTENT_PAGES, () => {
    return savelyResolvePage('/');
  });
  setHeadersAttachToBaseProps(res, req, baseProps, preview);
  return {
    ...baseProps,

    props: {
      ...(await serverSideTranslations('de', ['common'])),
      ...baseProps.props,
      preview: preview || false,
      publicationState: previewData?.['publication_state'] || 'live',
    },
  };
};
