import { getCachedValue } from '@portals/core-immobilien/src/utils';
import { SingleNode } from '@portals/root/src/consumers/Consumer/Consumer';
import { RequestHeadersProvider } from '@portals/root/src/contexts/request-headers';
import { usePreviewSnack } from '@portals/root/src/hooks/usePreviewSnack';
import { IncomingHttpHeaders } from 'http';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

import { Consumer } from '../src/containers/Consumer/Consumer';
import { setHeadersAttachToBaseProps } from '../src/utils/myraHeaders';
import { savelyResolvePage } from '../src/utils/savelyResolvePage';
import { getEstateListPageProps } from '../src/utils/strapiUtils';

interface PageProps {
  page: SingleNode;
  preview: boolean;
  publicationState: string;
  requestHeaders: IncomingHttpHeaders;
}
export default function AnyPage({ page, preview, publicationState, requestHeaders }: PageProps): React.ReactElement {
  usePreviewSnack(preview, publicationState, `${page.slugPath}`);
  return (
    <RequestHeadersProvider requestHeaders={requestHeaders}>
      <Consumer node={page} key={`page.${page.id}`} />
    </RequestHeadersProvider>
  );
}

interface Query {
  slugs: string[];
  [key: string]: string | string[];
}

const CACHING_TIME_CONTENT_PAGES = +(process.env.CACHING_TIME_CONTENT_PAGES || 0);

export function getUrl(params: GetServerSidePropsContext<Query>): string {
  return ((params.query.slugs ?? []) as Array<string>)?.join('/');
}
export const getServerSideProps: GetServerSideProps = async function (params: GetServerSidePropsContext<Query>) {
  const slug = getUrl(params);
  const keySuffix = params.preview ? '-preview' : '';
  const cacheKey = `baseprops-${slug}${keySuffix}`;
  const baseProps = await getCachedValue(cacheKey, params.preview ? 0 : CACHING_TIME_CONTENT_PAGES, async () => {
    const resolvedPage = await savelyResolvePage(slug, params.previewData?.['publication_state']);
    return getEstateListPageProps(resolvedPage);
  });
  if (!baseProps?.props) {
    return baseProps;
  }
  setHeadersAttachToBaseProps(params.res, params.req, baseProps, params.preview);

  return {
    ...baseProps,
    props: {
      ...(await serverSideTranslations('de', ['common'])),
      ...baseProps.props,
      preview: params.preview || false,
      publicationState: params.previewData?.['publication_state'] || 'live',
    },
  };
};
